<template>
    <div class="wrap">
        <my-header></my-header>
        <div v-show="$i18n.locale == 'EN'">
            <div class="banner_en">
                <transition name="slide-fade">
                    <div v-if="show">
                        <p>SiGREAL Talents Plan</p>
                        <div>Doing the Right Thing, Even When It's Hard</div>
                    </div>
                </transition>
                <div class="banner_left"></div>
                <div class="banner_right"></div>
            </div>
            <div class="section_en">
                <div class="element_img element_img1"><img src="../../public/images/join/en/element_1.png" /></div>
                <div class="element_img element_img2"><img src="../../public/images/join/en/element_2.png" /></div>
                <div class="element_img element_img3"><img src="../../public/images/join/en/element_3.png" /></div>
                <div class="element_img element_img4"><img src="../../public/images/join/en/element_4.png" /></div>
                <div class="element_img element_img5"><img src="../../public/images/join/en/element_5.png" /></div>
                <div class="element_img element_img6"><img src="../../public/images/join/en/element_6.png" /></div>
                <ul class="plan_box">
                    <li class="plan_item plan_item1">
                        <transition name="slide-left-fade">
                            <div class="plan_text" v-show="planItem1Show">
                                <p>What is the SiGREAL Talents Plan?</p>
                                <b class="plan_line"></b>
                                <div>The plan aims to recruit technology talents from TOP institutions around the world who are passionate about changing the world with advanced AI.</div>
                            </div>
                        </transition>
                        <div class="plan_img">
                            <img src="../../public/images/join/en/join_img1.jpg" alt="" />
                        </div>
                    </li>
                    <li class="plan_item plan_item2">
                        <transition name="slide-right-fade">
                            <div class="plan_text" v-show="planItem2Show">
                                <p>How about our team?</p>
                                <b class="plan_line"></b>
                                <!-- <div style="margin-bottom: 0.25rem;">Your partners are from Tsinghua University, CAS (China Academy of Science), Boston University, NYU (New York University), and some other famous universities.</div> -->
                                <div>
                                    SiGReal Tech was founded with a simple mission: Empower transportation industry with cutting-edge machine learning and AI techniques. Our team is committed to deliver on that mission. The passion, dedication, and expertise of our members have deeply shaped SiGReal
                                    Tech and made it a great place to work.
                                </div>
                            </div>
                        </transition>
                        <div class="plan_img">
                            <img src="../../public/images/join/en/join_img2.jpg" alt="" />
                        </div>
                    </li>
                    <li class="plan_item plan_item3">
                        <transition name="slide-left-fade">
                            <div class="plan_text" v-show="planItem3Show">
                                <!-- <p>The position: </p> -->
                                <p>What will you do when you join the plan?</p>
                                <b class="plan_line"></b>
                                <div>If you are looking for fast-paced environments and using deep learning technology to solve real-world problems, this will be the perfect opportunity for you.</div>
                                <div>There are no specific job descriptions, as long as you are good and passionate, we can even tailor a position for you.</div>
                            </div>
                        </transition>
                        <div class="plan_img">
                            <img src="../../public/images/join/en/join_img3.jpg" alt="" />
                        </div>
                    </li>
                    <li class="plan_item plan_item4">
                        <transition name="slide-right-fade">
                            <div class="plan_text" v-show="planItem4Show">
                                <p>Who are we looking for?</p>
                                <b class="plan_line"></b>
                                <div>If you have been studying in or graduated from a TOP 50 institutions in the world within two years, and meet any of the following criteria, you are welcome to apply:</div>
                                <ul>
                                    <li><span>BS (Bachelor of Science) in Computer Science, Mathematics, or a related quantitative field;</span></li>
                                    <li><span>Published as the first author in top-tier machine learning conferences or journals, such as NeurIPS, ICML, ICLR, ICCV, CVPR, ACL, etc.</span></li>
                                    <li><span>Participated in famous programming competitions, or other comparable experiences；</span></li>
                                    <li><span>Internship experience in well-known startups or world-renowned large companies.</span></li>
                                </ul>
                                <el-button type="primary" @click="dialogFormVisible = true" round>APPLY NOW</el-button>
                                <!-- <div class="plan_btn">APPLY NOW</div> -->
                            </div>
                        </transition>
                        <div class="plan_img">
                            <img src="../../public/images/join/en/join_img4.jpg" alt="" />
                        </div>
                    </li>
                </ul>
                <div class="contact_us">
                    <div class="contact_title">Other Positions</div>
                    <div class="contact_bg">
                        <transition name="slide-fade">
                            <div class="contact_box" v-if="contacShow">
                                <!-- <p>We continue to recruit machine learning algorithm engineers, big data engineers, senior Java engineers, and business elites;</p> -->
                                <p>If you are passionate about innovation and technology, you are welcome to join us!</p>
                                <div class="contact_info">
                                    <div class="contact_info_item">
                                        <div><img src="../../public/images/join/en/phone_icon.png" /></div>
                                        <span>010-87856177</span>
                                    </div>
                                    <div class="contact_info_item">
                                        <div><img src="../../public/images/join/en/email_icon.png" /></div>
                                        <span>hr@honglingtech.com.cn</span>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="$i18n.locale == 'CN'">
            <div class="section">
                <div class="banner">
                    <transition name="slide-fade">
                        <div v-if="show">
                            <p>加入我们</p>
                            <div>和我们一起，做正确且难的事</div>
                        </div>
                    </transition>
                    <div class="banner_left"></div>
                    <div class="banner_right"></div>
                </div>
                <div class="element_bg">
                    <div class="element_img">
                        <img src="../../public/images/join/element_bg.png" />
                    </div>
                </div>
                <div class="content">
                    <div class="scroll_top2">
                        <div class="culture_wrap">
                            <p class="detail_title" style="margin-bottom: 0.4rem; margin-top: -0.3rem">企业文化</p>
                            <div class="second_text">希望你是一个开放、勤奋、迭代的奋斗者，可以与先进人工智能团队一起在你擅长的专业领域做出成绩，面对未知领域和创新知识愿意学习成长，面对困难展示强大的内心，为身边的伙伴输出积极乐观正能量。</div>
                            <div class="card_box">
                                <div class="card" v-for="(item, index) in cardArr" :key="index">
                                    <div class="bgc"></div>
                                    <p class="title">0{{ index + 1 }}.</p>
                                    <p class="txt">{{ $t('Public.JoinUs.card[' + index + '].title') }}</p>
                                    <p class="txt">{{ $t('Public.JoinUs.card[' + index + '].text') }}</p>
                                </div>
                            </div>
                            <div class="culture_group" v-if="0">
                                <div class="quxian"><img src="../../public/images/join/quxian.png" /></div>
                                <div class="culture_group_slide">
                                    <div class="swiper-container culture_slide">
                                        <div class="swiper-wrapper">
                                            <!-- <ul class="culture_list"> -->
                                            <div class="swiper-slide culture_list" style="width: 0.98rem" v-for="item in cultureData" :key="item.id">
                                                <div style="width: 0.98rem"><img :src="require('../../public/images/join/' + item.url)" /></div>
                                            </div>
                                            <!-- </ul> -->
                                        </div>
                                        <div class="swiper-button-prev btn swiper-button-black"></div>
                                        <div class="swiper-button-next btn swiper-button-black"></div>
                                    </div>
                                    <div class="culture_item">
                                        <div class="culture_img"><img :src="require('../../public/images/join/' + cultureData[checkedCultureIndex].urlActive)" /></div>
                                        <div class="culture_text">
                                            <p>{{ cultureData[checkedCultureIndex].title }}</p>
                                            <div>{{ cultureData[checkedCultureIndex].details }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="detail_title">查看职位</p>
                        <div class="join_box" id="certify">
                            <div class="swiper-container join_slide">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide join_wall join_wall_1">
                                        <div>
                                            <p>社会招聘</p>
                                            <span>SOCIAL RECRUITMENT</span>
                                        </div>
                                    </div>
                                    <div class="swiper-slide join_wall join_wall_2">
                                        <div>
                                            <p>校园招聘</p>
                                            <span>CAMPUS RECRUITMENT</span>
                                        </div>
                                    </div>
                                    <div class="swiper-slide join_wall join_wall_3">
                                        <div>
                                            <p>瓴先人才计划</p>
                                            <span>SiGREAL TALENTS PLAN</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-button-prev btn swiper-button-black"></div>
                                <div class="swiper-button-next btn swiper-button-black"></div>
                            </div>
                        </div>
                    </div>
                    <div class="join_post">
                        <el-tabs v-model="activeName" @tab-click="handleClick" class="post_tab">
                            <el-tab-pane v-for="(item, index) in postList[checkedPostIndex]" :key="index" :label="item.name" :name="item.id">
                                <div>
                                    <div class="post_drawer" v-if="checkedPost.name == '瓴先人才计划'">
                                        <p class="post_small_title">瓴先人才计划</p>
                                        <ul class="post_ul">
                                            <li>面向全球TOP 50院校招募科技人才，培养及储备能引领未来人工智能与机器学习的业内顶尖专家。</li>
                                        </ul>
                                        <p class="post_small_title post_small_title1">你将会做什么：</p>
                                        <ul class="post_ul">
                                            <li>进入核心团队，主导或参与核心项目，解决面向保险科技、大出行、新能源等极具挑战性的行业级场景技术问题。</li>
                                        </ul>
                                        <p class="post_small_title post_small_title1">你的工作伙伴</p>
                                        <ul class="post_ul">
                                            <li>来自清华大学、中科院、波士顿大学、纽约大学等国际知名院校；</li>
                                            <li>曾就职于世界500强企业及顶级科技公司核心岗位；</li>
                                            <li>拥有车联网大数据分析、人工智能、深度机器学习、保险精算等资深专业背景。</li>
                                        </ul>
                                        <p class="post_small_title post_small_title1">申请要求</p>
                                        <p class="post_small_title post_small_title1">如果你全球TOP 50院校在读或毕业两年内，只要符合以下任意一类即可申请：</p>
                                        <ul class="post_ul">
                                            <li>1、计算机/数学/物理/统计等相关专业，成绩Top 5%；</li>
                                            <li>2、在国际顶级会议或期刊上发表过机器学习相关论文；</li>
                                            <li>3、参加知名数据挖掘竞赛如KDDCup等并取得优异名次；</li>
                                            <li>4、参加知名编程比赛如ACM/ICPC并取得优异名次；</li>
                                            <li>5、Top创业公司或世界知名大公司实习经历。</li>
                                        </ul>
                                        <p class="post_small_title post_small_title1">关于职位：无具体岗位JD，只要你足够优秀，可量身定制</p>
                                        <ul class="post_ul">
                                            <li>诚邀你加入“瓴先人才计划”，与我们共同成长；</li>
                                            <li>希望你保持好奇心，从深度/广度不断拓宽自己的能力边界；</li>
                                            <li>通过人工智能技术推动时代发展。</li>
                                        </ul>
                                        <el-button class="submit_btn" size="medium" type="primary" @click="handleApplyPost">申请该职位</el-button>
                                    </div>
                                    <div class="post_drawer" v-else>
                                        <p class="post_small_title">岗位职责</p>
                                        <ul class="post_ul">
                                            <li v-for="(item, index) in checkedPost.duty" :key="index">{{ item }}</li>
                                        </ul>
                                        <p class="post_small_title">任职要求</p>
                                        <ul class="post_ul">
                                            <li v-for="(item, index) in checkedPost.request" :key="index" v-html="item"></li>
                                        </ul>
                                        <el-button class="submit_btn" size="medium" type="primary" @click="handleApplyPost">申请该职位</el-button>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-if="dialogFormVisible">
            <div class="modal_box">
                <div class="dialog_title">
                    <p>{{ $t('Public.JoinUs.modal.title') }}</p>
                    <div>{{ $t('Public.JoinUs.modal.text1') }}</div>
                    <div>{{ $t('Public.JoinUs.modal.text2') }}</div>
                </div>
                <div class="form_box">
                    <el-form :model="formData">
                        <el-form-item>
                            <el-input v-model="formData.username" :placeholder="$t('Public.JoinUs.modal.placeholder.name')"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="formData.phone" type="tel" maxlength="11" :placeholder="$t('Public.JoinUs.modal.placeholder.phone')"></el-input>
                        </el-form-item>
                        <!-- <el-form-item>
					<el-input v-model="formData.jobApplication" placeholder="请填写您要申请的职位"></el-input>
				  </el-form-item> -->
                    </el-form>
                    <el-upload style="text-align: center" :action="baseUrl + '/api/uploadeImage'" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <div class="uplod">
                            <template v-if="fileName">
                                <p>{{ fileName }}</p>
                            </template>
                            <template v-else>
                                <div><img src="../../public/images/join/upload_icon.png" /></div>
                                <span>{{ $t('Public.JoinUs.modal.fileName') }}</span>
                            </template>
                        </div>
                    </el-upload>

                    <div class="modal_btn">
                        <el-button @click="dialogFormVisible = false">{{ $t('Public.JoinUs.modal.button1') }}</el-button>
                        <el-button :disabled="!isSubmit" type="primary" @click="onSubmit">{{ $t('Public.JoinUs.modal.button2') }}</el-button>
                    </div>
                </div>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import { getScrollParent } from '@/assets/js/common.js';
var _this = '';
export default {
    name: 'JoinUs',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            activeName: 'sz2',
            swiperCulture: null,
            swiperJoin: null,
            baseUrl: '',
            show: false,
            planItem1Show: false,
            planItem2Show: false,
            planItem3Show: false,
            planItem4Show: false,
            contacShow: false,
            formData: {
                username: '',
                phone: '',
                jobApplication: '',
                file: ''
            },
            checkedCultureIndex: 0,
            cultureData: [
                {
                    id: 1,
                    url: 'culture_1.png',
                    urlActive: 'culture_active1.png',
                    title: '成就客户',
                    details: '才是成就自己'
                },
                {
                    id: 2,
                    url: 'culture_2.png',
                    urlActive: 'culture_active2.png',
                    title: '务实落地',
                    details: '实事求是、真正解决问题'
                },
                {
                    id: 3,
                    url: 'culture_3.png',
                    urlActive: 'culture_active3.png',
                    title: '正直担当',
                    details: '诚实大气、勇于担当、有责任心'
                },
                {
                    id: 4,
                    url: 'culture_4.png',
                    urlActive: 'culture_active4.png',
                    title: '延迟满足感',
                    details: '习惯做正确且难的事'
                },
                {
                    id: 5,
                    url: 'culture_5.png',
                    urlActive: 'culture_active5.png',
                    title: '强执行力',
                    details: '敢打仗、打胜仗'
                },
                {
                    id: 6,
                    url: 'culture_6.png',
                    urlActive: 'culture_active6.png',
                    title: '乐观进取',
                    details: '追求理想、挑战困难、永不放弃'
                }
            ],
            checkedItem: {},
            postList: [
                [
                    // {name:'首席架构师',id:'sz1'},
                    { name: '算法工程师', id: 'sz2' },
                    { name: 'AI产品经理', id: 'sz3' },
                    { name: '商务总监', id: 'sz5' }
                ],
                [
                    { name: '机器学习算法', id: 'xz1' },
                    { name: '管培生', id: 'xz2' }
                ],
                [{ name: '瓴先人才计划', id: 'lx1' }]
            ],
            checkedPostIndex: 0,
            checkedPost: {
                id: 'sz2',
                name: '算法工程师',
                duty: [
                    '1、公司业务相关AI能力开发与应用，探索前沿深度学习/机器学习方法的边界，包括但不限于深度神经网络，图神经网络，时间序列模型，监督和自监督等学习算法；',
                    '2、与团队紧密合作进行模型效果和效率的优化，并配合将模型部署到生产环境下，支持产品快速落地；',
                    '3、深入业务场景，持续关注人工智能领域的最新动态和进展，不断迭代与创新。'
                ],
                request: [
                    '1、计算机/电子信息/自动化/数学/物理/统计等相关专业，硕士及以上学历；',
                    '2、数学基础扎实，对深度学习、机器学习的原理和主流算法有比较清晰的理解；',
                    '3、熟悉linux开发环境，熟练掌握Python环境下快速的算法实现，熟悉至少一种深度学习框架，PyTorch，Tensorflow，Caffe，etc；',
                    '4、 有深度学习、机器学习产品级应用经验者优先；',
                    '5、积极乐观，热爱挑战，不循规蹈矩者优先；',
                    '6、有以下至少某一方面的背景：计算机视觉（CV）、自然语言处理（NLP）、 语音识别、机器学习及金融领域算法应用。'
                ]
            },
            fullscreenLoading: false,
            fileName: '',
            joinType: 1,
            isSubmit: true,
            dialogFormVisible: false,
            cardArr: [{}, {}, {}, {}, {}]
        };
    },
    methods: {
        //点击加入我们照片墙的招聘块
        handleJoinWall(val) {
            this.checkedPostIndex = val;
            this.activeName = this.postList[this.checkedPostIndex][0].id;
            this.handleClick();
        },
        //tab-click
        handleClick(tab) {
            this.$t('Public.PostDetail.postDetails').forEach((val, key) => {
                if (this.activeName == val.id) {
                    this.checkedPost = val;
                }
            });
        },
        windowScroll(e) {
            if (this.$i18n.locale == 'CN') {
                this.show = true;
                return false;
            }
            //获取根节点字体大小
            var _fontSize = parseFloat(document.documentElement.style.fontSize);
            // 获取视窗高度
            var domHight = document.body.offsetHeight;
            //获取浏览器视口的高度
            var innerHeight = window.innerHeight;
            // dom滚动位置
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            var bannerHeight = document.querySelector('.banner_en').offsetHeight;
            var planItem1Height = document.querySelector('.plan_item1').offsetHeight;
            var planItem2Height = document.querySelector('.plan_item2').offsetHeight;
            var planItem3Height = document.querySelector('.plan_item3').offsetHeight;
            var planItem4Height = document.querySelector('.plan_item4').offsetHeight;
            var planBoxHeight = document.querySelector('.plan_box').offsetHeight;
            var contactTitleHeight = document.querySelector('.contact_title').offsetHeight;
            // console.log(innerHeight,scrollTop,planItem1Height,_fontSize);
            // 显示文字
            if (scrollTop <= innerHeight * 0.1) {
                this.show = true;
            }
            // 第一个文字卡片显示
            if (scrollTop < bannerHeight + 1.5 * _fontSize + planItem1Height * 0.3) {
                // if(innerHeight-(bannerHeight+1.5*_fontSize+planItem1Height/2)>0){
                this.planItem1Show = true;
            }
            // 第二个文字卡片显示
            if (scrollTop >= bannerHeight + planItem1Height + 1.5 * 2 * _fontSize + planItem2Height / 2 - innerHeight) {
                this.planItem2Show = true;
            }
            // 第三个文字卡片显示
            if (scrollTop >= bannerHeight + planItem1Height + planItem2Height + 1.5 * 3 * _fontSize + planItem3Height / 2 - innerHeight) {
                this.planItem3Show = true;
            }
            // 第四个文字卡片显示
            if (scrollTop >= bannerHeight + planItem1Height + planItem2Height + planItem3Height + 1.5 * 4 * _fontSize + planItem4Height / 2 - innerHeight) {
                this.planItem4Show = true;
            }
            //加入我们卡片显示
            if (scrollTop >= bannerHeight + planBoxHeight + contactTitleHeight * 4 - innerHeight) {
                this.contacShow = true;
            }
            //隐藏文字
            if (scrollTop >= innerHeight * 0.67) {
                this.show = false;
            }
            // 第一个文字卡片隐藏
            if (scrollTop > bannerHeight + 1.5 * _fontSize + planItem1Height) {
                this.planItem1Show = false;
            }
            // 第二个文字卡片隐藏
            if (scrollTop > bannerHeight + 1.5 * 2 * _fontSize + planItem1Height + planItem2Height || scrollTop < bannerHeight + planItem1Height + 1.5 * 2 * _fontSize - innerHeight) {
                this.planItem2Show = false;
            }
            // 第三个文字卡片隐藏
            if (scrollTop > bannerHeight + 1.5 * 3 * _fontSize + planItem1Height + planItem2Height + planItem3Height || scrollTop < bannerHeight + planItem1Height + planItem2Height + 1.5 * 3 * _fontSize - innerHeight) {
                this.planItem3Show = false;
            }
            // 第四个文字卡片隐藏
            if (scrollTop > bannerHeight + planBoxHeight || scrollTop < bannerHeight + planItem1Height + planItem2Height + planItem3Height + 1.5 * 4 * _fontSize - innerHeight) {
                this.planItem4Show = false;
            }
            //隐藏加入我们卡片
            if (scrollTop < bannerHeight + planBoxHeight + contactTitleHeight - innerHeight) {
                this.contacShow = false;
            }
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            console.log(res, file);
            this.fullscreenLoading.close();
            this.formData.file = res.data;
            this.fileName = file.name;
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            console.log(file.size / 1024);
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        //点击申请该职位按钮
        handleApplyPost() {
            this.dialogFormVisible = true;
            this.formData = {
                username: '',
                phone: '',
                jobApplication: '',
                file: ''
            };
            this.formData.jobApplication = this.checkedPost.name;
        },
        onSubmit() {
            var _this = this;
            if (!_this.formData.username) {
                this.$notify({ title: this.$t('Public.JoinUs.modal.nameHint'), showClose: false, type: 'error' });
                return false;
            }
            if (!_this.formData.phone) {
                this.$notify({ title: this.$t('Public.JoinUs.modal.phoneHint1'), showClose: false, type: 'error' });
                return false;
            } else if (this.verificationPhone()) {
                this.$notify({ title: this.$t('Public.JoinUs.modal.phoneHint2'), showClose: false, type: 'error' });
                return false;
            }
            if (!_this.formData.file) {
                this.$notify({ title: this.$t('Public.JoinUs.modal.resumeHint'), showClose: false, type: 'error' });
                return false;
            }
            this.isSubmit = false;
            this.instance
                .get('/addRecruitServiceTwo', {
                    params: this.formData
                })
                .then((res) => {
                    this.isSubmit = true;
                    this.dialogFormVisible = false;
                    this.formData = {
                        username: '',
                        phone: '',
                        file: '',
                        jobApplication: ''
                    };
                    this.fileName = '';
                    if (res.data.code == 1) {
                        this.$notify({
                            title: this.$i18n.locale == 'EN' ? 'succeed' : '成功',
                            message: res.data.msg,
                            type: 'success'
                        });
                    } else {
                        this.$notify({
                            title: this.$i18n.locale == 'EN' ? 'failed' : '失败',
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                });
        },
        verificationPhone() {
            var _phone = this.formData.phone;
            var reg = /^1[3-9][0-9]\d{4,8}$/i; //验证手机正则(输入前7位至11位)
            if (_phone == '') {
                return true;
            } else if (_phone.length < 11) {
                return true;
            } else if (!reg.test(_phone)) {
                return true;
            } else {
                return false;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.windowScroll);
    },
    mounted() {
        window.vue = this;
        _this = this;
        window.addEventListener('scroll', this.windowScroll);
        this.baseUrl = window.location.protocol + '//' + window.location.host;
        this.show = true;
        this.planItem1Show = true;

        this.swiperCulture = new Swiper('.culture_slide', {
            loop: true,
            speed: 1000,
            slidesPerView: 3,
            spaceBetween: 0,
            centeredSlides: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            on: {
                slideChange: function () {
                    setTimeout(() => {
                        _this.checkedCultureIndex = this.realIndex;
                    }, 600);
                }
            }
        });
        //加入我们slide
        this.swiperJoin = new Swiper('.join_slide', {
            watchSlidesProgress: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
            loopedSlides: 3,
            // autoplay: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            on: {
                progress: function (progress) {
                    for (var i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i);
                        var slideProgress = this.slides[i].progress;
                        var modify = 1;
                        if (Math.abs(slideProgress) > 1) {
                            modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                        }
                        var translate = slideProgress * modify * 4.5 + 'rem';
                        var scale = 1 - Math.abs(slideProgress) / 4;
                        var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                        slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                        slide.css('zIndex', zIndex);
                        slide.css('opacity', 1);
                    }
                },
                setTransition: function (transition) {
                    for (var i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i);
                        slide.transition(transition);
                    }
                },
                slideChange: function () {
                    _this.handleJoinWall(this.realIndex);
                }
            }
        });
    }
};
</script>
<style scoped>
@import '../assets/css/public.css';
.culture_group {
    width: 100%;
    height: 5.3rem;
    position: relative;
    margin-top: 1rem;
}
.quxian {
    position: absolute;
    top: 1.64rem;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 0.5rem;
}
.culture_group_slide {
    width: 15.58rem;
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding-top: 4.9rem;
    overflow: hidden;
}
.culture_pagination {
    margin: 0 auto 0;
    width: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.culture_pagination div {
    width: 0.4rem;
    cursor: pointer;
}
.culture_slide {
    position: absolute;
    top: 1.4rem;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 5rem;
    /* height: 0.98rem; */
}
/* .culture_list{
		display: flex;
	} */
.culture_item {
    position: absolute;
    top: 0;
    left: 5.9rem;
    z-index: 100;
}
.culture_list {
    padding-left: 2rem;
    width: 0.98rem;
}
.culture_wrap {
    width: 100%;
    /* height: 9.9rem; */
    background: url(../../public/images/join/bg.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 1rem;
    margin-bottom: 1.2rem;
}

.second_text {
    text-indent: 2em;
    line-height: 2;
    text-align: left;
    width: 15.58rem;
    font-size: 0.2rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 1rem;
}
.culture_wrap .card_box {
    width: 15.58rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.card_box .card {
    width: 2.5rem;
    height: 2.5rem;
    /* border: 1px solid red; */
    background: url(../../public/images/join/img0.png) no-repeat;
    background-size: cover;
    background-position: center top;
    position: relative;
}
.card:nth-child(2) {
    background: url(../../public/images/join/img1.png) no-repeat;
    background-size: cover;
    background-position: center top;
}
.card:nth-child(3) {
    background: url(../../public/images/join/img2.png) no-repeat;
    background-size: cover;
    background-position: center top;
}
.card:nth-child(4) {
    background: url(../../public/images/join/img3.png) no-repeat;
    background-size: cover;
    background-position: center top;
}
.card:nth-child(5) {
    background: url(../../public/images/join/img4.png) no-repeat;
    background-size: cover;
    background-position: center top;
}
.card .bgc {
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    position: absolute;
    left: 0;
    z-index: 5;
}
.card .title {
    position: absolute;
    left: 0.16rem;
    font-size: 0.2rem;
    line-height: 0.28rem;
    color: #fff;
    font-weight: 600;
    z-index: 99;
    top: 0.16rem;
}
.card .txt:nth-child(3) {
    position: absolute;
    left: 0.16rem;
    font-size: 0.2rem;
    line-height: 0.28rem;
    color: #fff;
    font-weight: 600;
    z-index: 99;
    bottom: 0.5rem;
}
.card .txt:nth-child(4) {
    position: absolute;
    left: 0.16rem;
    font-size: 0.2rem;
    line-height: 0.28rem;
    color: #fff;
    font-weight: 600;
    z-index: 99;
    bottom: 0.16rem;
    font-size: 0.16rem;
    font-weight: 400;
}
#certify .join_wall {
    /* transition: all 0.3s; */
    width: 9rem;
    height: 4rem;
    background: url(../../public/images/join/join_bg1.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
}
#certify .join_wall_2 {
    background: url(../../public/images/join/join_bg2.png) no-repeat;
    background-size: 100% 100%;
}
#certify .join_wall_3 {
    background: url(../../public/images/join/join_bg3.png) no-repeat;
    background-size: 100% 100%;
}
.join_wall div {
    margin: auto;
    text-align: center;
    font-size: 0.2rem;
    display: none;
}
.swiper-slide-active div {
    display: block;
}
.join_wall p {
    font-size: 0.4rem;
    font-weight: 500;
    margin-bottom: 0.1rem;
}
.join_box {
    width: 100%;
    padding: 1.16rem 3.6rem 0;
}
.swiper-button-next,
.swiper-button-prev {
    width: 0.4rem;
    height: 0.4rem;
    top: 3.6rem;
    z-index: 999;
}
.swiper-button-prev.swiper-button-black {
    background: url(../../public/images/join/left_icon.png) no-repeat;
    background-size: 100% 100%;
    left: 7.1rem;
}
.swiper-button-next.swiper-button-black {
    background: url(../../public/images/join/right_icon.png) no-repeat;
    background-size: 100% 100%;
    left: auto;
    right: 7.1rem;
}
#certify .swiper-button-next,
#certify .swiper-button-prev {
    width: 0.44rem;
    height: 0.44rem;
    top: 2.1rem;
}
#certify .swiper-button-prev.swiper-button-black {
    background: url(../../public/images/join/left.png) no-repeat;
    background-size: 100% 100%;
    left: 0.5rem;
}
#certify .swiper-button-next.swiper-button-black {
    background: url(../../public/images/join/right.png) no-repeat;
    background-size: 100% 100%;
    left: auto;
    right: 0.5rem;
}
.culture_box {
    width: 12rem;
    margin: 1.3rem auto 0;
}
.culture_box /deep/.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
}
.culture_box /deep/.swiper-pagination-bullet {
    width: 0.12rem;
    height: 0.12rem;
}

.culture_box .swiper-slide {
    width: 3.6rem;
    height: 4.2rem;
}
.culture_item {
    width: 3.8rem;
    height: 4.5rem;
    padding-top: 0.68rem;
    background: #ffffff;
    box-shadow: 0px 0.08rem 0.13rem 0.05rem rgba(0, 0, 0, 0.05);
    border-radius: 0.1rem;
}
.culture_img {
    width: 1.7rem;
    margin: 0 auto 0.2rem;
}
.culture_text {
    font-size: 0.24rem;
    color: #666;
    text-align: center;
}
.culture_text p {
    font-size: 0.3rem;
    margin-bottom: 0.1rem;
    font-weight: 500;
    color: #333333;
}
.slide-left-fade-enter-active,
.slide-right-fade-enter-active {
    transition: all 1.2s ease;
}
.slide-left-fade-leave-active,
.slide-right-fade-leave-active {
    transition: all 1.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-left-fade-enter,
.slide-left-fade-leave-to {
    transform: translateX(-1.8rem);
    opacity: 0;
}
.slide-right-fade-enter,
.slide-right-fade-leave-to {
    transform: translateX(1.8rem);
    opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(1rem);
    opacity: 0;
}
.contact_us {
    position: absolute;
    top: 31.6rem;
    left: 0;
    z-index: 60;
    width: 100%;
}
.contact_title {
    width: 6.47rem;
    height: 1.53rem;
    line-height: 1.53rem;
    background: url(../../public/images/join/en/welcome_bg.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-size: 0.4rem;
    font-weight: 600;
    color: #333;
    margin: 0 auto 0.39rem;
}
.contact_bg {
    width: 17.85rem;
    height: 7.54rem;
    background: url(../../public/images/join/en/bg3.png) no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    padding-top: 0.9rem;
}
.contact_box {
    width: 12rem;
    height: 5rem;
    background: #ffffff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.1rem;
    margin: 0 auto 0;
    padding: 0.85rem 1rem 0;
    color: #333;
}
.contact_box p {
    line-height: 2;
}
.contact_info {
    width: 8rem;
    margin: 0.8rem auto 0;
    display: flex;
    justify-content: space-between;
}
.contact_info_item {
    display: flex;
    align-items: center;
}
.contact_info_item div {
    width: 1rem;
    margin-right: 0.2rem;
    transition: all 0.6s;
}
.contact_info_item div:hover {
    transform: translateY(-0.2rem);
}
.section_en {
    position: relative;
    width: 100%;
    min-height: 41.35rem;
    font-size: 0.2rem;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
}
.plan_box {
    width: 100%;
    height: 31.6rem;
    position: relative;
}
.plan_item {
    width: 12.2rem;
    min-height: 4.5rem;
    position: absolute;
    top: 1.5rem;
    left: 3.5rem;
    z-index: 30;
    /* margin-top: 1.5rem; */
}
.plan_item2 {
    top: 7.5rem;
    min-height: 5.38rem;
}
.plan_item3 {
    top: 14.38rem;
    min-height: 6.15rem;
}
.plan_item4 {
    top: 22rem;
    min-height: 8.63rem;
}
.plan_item1 .plan_text {
    width: 6.88rem;
    min-height: 3.2rem;
    background: #ffffff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 0.4rem 0.35rem;
    position: absolute;
    top: 0.65rem;
    left: 0;
    z-index: 50;
}
.plan_item1 .plan_img {
    width: 6.5rem;
    height: 4.5rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 40;
}
.plan_img {
    overflow: hidden;
}
.plan_img img {
    transition: all 0.8s;
}
.plan_img img:hover {
    transform: scale(1.3);
}
.plan_item2 .plan_text {
    width: 8rem;
    min-height: 5rem;
    background: #f2fbff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 0.4rem 0.75rem 0.4rem 2.6rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 40;
}
.plan_item2 .plan_img {
    width: 6rem;
    height: 4.5rem;
    position: absolute;
    top: 0.88rem;
    left: 0;
    z-index: 50;
}
.plan_item3 .plan_text {
    width: 9rem;
    min-height: 4.4rem;
    background: #fff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 0.4rem 0.75rem 0.4rem 0.4rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
}
.plan_item3 .plan_img {
    width: 6.5rem;
    height: 4.5rem;
    position: absolute;
    top: 1.65rem;
    right: 0;
    z-index: 50;
}
.plan_item3 .plan_text div {
    width: 4.6rem;
}
.plan_item4 .plan_text {
    width: 7.78rem;
    min-height: 5.7rem;
    background: #e8f8ff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 0.35rem 0.5rem 0.4rem 0.4rem;
    position: absolute;
    top: 0.65rem;
    right: 0;
    z-index: 50;
}
.plan_item4 .plan_text ul {
    margin: 0.26rem 0 0.35rem;
}
.plan_item4 .plan_text li {
    width: 100%;
    display: flex;
    /* align-items: center; */
    line-height: 2;
}
.plan_item4 .plan_text li::before {
    display: block;
    content: '';
    width: 0.08rem;
    height: 0.08rem;
    background: #05a3e4;
    border-radius: 50%;
    margin-right: 0.16rem;
    margin-top: 0.17rem;
}
.plan_item4 .plan_text li span {
    width: 6.5rem;
}
.plan_item4 .plan_btn {
    width: 2rem;
    height: 0.54rem;
    line-height: 0.54rem;
    background: #05a3e4;
    border-radius: 0.3rem;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.plan_item4 .plan_btn:hover {
    box-shadow: 1px 3px 6px 0px rgba(51, 51, 51, 0.35);
}
.plan_item4 .plan_img {
    width: 6.5rem;
    height: 8.63rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
}
.plan_text div {
    line-height: 2;
    text-align: justify;
}
.plan_text p {
    font-size: 0.34rem;
    font-weight: 600;
    color: #05a3e4;
}
.plan_line {
    width: 0.8rem;
    height: 0.04rem;
    background: #05a3e4;
    margin: 0.1rem 0 0.3rem;
    display: inline-block;
}
.section_en .element_img {
    width: 7.39rem;
    height: 5.62rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    transition: all 0.8s;
}
.section_en .element_img:hover {
    transform: scale(1.2);
}
.section_en .element_img2 {
    width: 6.25rem;
    height: 6.55rem;
    top: 3.65rem;
    left: auto;
    right: 0;
}
.section_en .element_img3 {
    width: 6.9rem;
    height: 7.35rem;
    top: 10.71rem;
    left: 0;
}
.section_en .element_img4 {
    width: 7.16rem;
    height: 7.02rem;
    top: 16.54rem;
    left: auto;
    right: 0;
}
.section_en .element_img5 {
    width: 7.39rem;
    height: 7.87rem;
    top: 23.85rem;
    left: 0;
}
.section_en .element_img6 {
    width: 4.84rem;
    height: 3.92rem;
    top: auto;
    bottom: 0;
    left: 0;
}
.join_post {
    padding: 1.3rem 3.6rem 0;
}
/deep/.el-tabs__item {
    font-size: 0.24rem;
    color: #333;
    padding: 0 0.35rem;
}
/deep/.el-tabs__item.is-active {
    font-weight: 600;
    color: #05a3e4;
}
/deep/.el-tabs__nav-wrap::after {
    display: none;
}
/deep/.el-tabs__active-bar {
    background-color: #05a3e4;
}
.uplod {
    min-width: 2rem;
    height: 0.54rem;
    background: #e8f8ff;
    border-radius: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.18rem;
    color: #333333;
    margin-top: 0.2rem;
    cursor: pointer;
    padding: 0 0.26rem;
}
.uplod div {
    width: 0.3rem;
    margin-right: 0.1rem;
}
.modal_btn {
    text-align: center;
    margin-top: 0.4rem;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
}
.modal_box {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 1100;
    transform: translate(-50%, -50%);
    background-color: #fff;
}
.dialog_title {
    width: 7.2rem;
    height: 2.8rem;
    background: url(../../public/images/join/form_bg.png) no-repeat;
    background-size: 100% 100%;
    padding-top: 0.38rem;
    text-align: center;
    font-size: 0.32rem;
    font-weight: 500;
    color: #333;
}
.dialog_title p {
    margin-bottom: 0.1rem;
}
.dialog_title div {
    color: #666;
    font-size: 0.18rem;
    line-height: 1.8;
}
.form_box {
    padding: 0.3rem 1rem 0.5rem;
    font-size: 0.2rem;
}
.submit_btn {
    margin-top: 0.6rem;
}
/deep/#el-drawer__title {
    margin-bottom: 0.2rem;
}
.post_title {
    font-size: 0.22rem;
    font-weight: 600;
}
.post_small_title {
    font-size: 0.22rem;
    font-weight: 600;
    margin: 0.4rem 0 0.22rem;
}
.post_small_title1 {
    margin-top: 0.22rem;
}
.post_ul1 {
    margin-top: 0.4rem;
}
.post_ul li {
    line-height: 1.75;
}
/deep/.el-drawer__body {
    overflow-y: auto;
}
.post_drawer {
    color: #333;
    font-size: 0.2rem;
}
.banner,
.banner_en {
    width: 100%;
    height: 3.76rem;
    padding: 1.8rem 3.3rem 0 3.6rem;
    font-size: 0.22rem;
    font-weight: 300;
    background: url(../../public/images/join/banner.jpg) no-repeat;
    background-size: 100% 100%;
    line-height: 2;
    overflow: hidden;
    background-color: #396789;
}
.banner_en {
    background: url(../../public/images/join/en/banner.jpg) no-repeat;
    background-size: 100% 100%;
    /* text-align: center; */
    /* font-size: 0.4rem; */
    line-height: 1.4;
    padding-top: 2rem;
    position: relative;
}
.banner_left,
.banner_right {
    display: block;
    content: '';
    width: 6.88rem;
    height: 3.76rem;
    background: url(../../public/images/join/en/banner_left.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transition: transform 0.8s;
    transition-property: top, transform;
}
.banner_right {
    background: url(../../public/images/join/en/banner_right.png) no-repeat;
    background-size: 100% 100%;
    right: 0;
    left: auto;
}
.banner_left:hover,
.banner_right:hover {
    transform: scale(1.3);
    top: -0.865rem;
}
.banner p {
    font-size: 0.54rem;
    line-height: 1.39;
    margin-bottom: 0.2rem;
}
.banner_en p {
    font-size: 0.54rem;
    margin-bottom: 0.2rem;
}
.section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.element_bg {
    width: 100%;
    /* min-height: 27.4rem; */
    position: absolute;
    top: 5.76rem;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.element_bg .element_img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 8.27rem;
    height: 7.76rem;
}
.content {
    padding: 0 0 1rem;
    color: #333;
    min-height: 27.4rem;
    width: 100%;
}
.detail_title {
    font-size: 0.32rem;
    font-weight: 600;
    text-align: center;
}
.el-button--primary {
    /* background-color:#05A3E4; */
}
</style>
